import "antd/dist/antd.less";
import React from "react";
import { CookiesProvider } from "react-cookie";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import GlobalStyle from "./assets/styles/global-styles";
import AppLayout from "./components/common/AppLayout";
import PostRoute from "./components/routes/PostRoute";
import UserRoute from "./components/routes/UserRoute";
import SettingMain from "./components/setting/SettingMain";
import DirectoryRoute from "./components/routes/DirectoryRoute";

function App() {
  return (
    <BrowserRouter>
      <CookiesProvider>
        <GlobalStyle />
        <AppLayout>
          <Switch>
            <Route path="/" exact>
              <Redirect to="/post/dev" />
            </Route>
            <Route path="/post" component={PostRoute} />
            <Route path="/directory" component={DirectoryRoute} />
            <Route path="/user" component={UserRoute} />
            <Route path="/settings" component={SettingMain} />
            <Route path="*">
              <Redirect to="/post/dev" />
            </Route>
          </Switch>
        </AppLayout>
      </CookiesProvider>
    </BrowserRouter>
  );
}

export default App;
