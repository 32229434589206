import { GithubOutlined, MailOutlined } from "@ant-design/icons";
import { Space } from "antd";
import { BlogDivider, SidebarSection } from "../../assets/styles/common";

const About = () => {
  return (
    <SidebarSection>
      <BlogDivider />
      <Space size={5}>
        <GithubOutlined />
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://github.com/icecreamparlor"
        >
          Github
        </a>
      </Space>
      <br />

      <Space size={5}>
        <MailOutlined />
        <span>heejjeeh@gmail.com</span>
      </Space>
    </SidebarSection>
  );
};
export default About;
