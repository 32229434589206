import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Affix, Button, Form, Input, Space, Switch } from "antd";
import { Content } from "antd/lib/layout/layout";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { AppContext } from "../..";
import { apiAxios } from "../../api";
import { showMessage } from "../../util";
import ToastEditor from "../common/ToastEditor";
import * as queryString from "query-string";
import DirectoryForm from "../directory/DirectoryForm";

const PostForm = ({ match }: any) => {
  const { userId, isDarkMode, isLoggedIn } = useContext(AppContext);
  const { type, id } = useMemo(() => match.params, [match.params]);
  const history = useHistory();
  const location = useLocation();
  const query = useMemo(() => queryString.parse(location.search), [location]);
  const [title, setTitle] = useState("");
  const [contents, setContents] = useState<string | undefined>(undefined);
  const [contentsType, setContentsType] = useState<
    "markdown" | "wysiwyg" | undefined
  >(undefined);
  const [isVisible, setIsVisible] = useState(true);
  const [loading, setLoading] = useState(false);
  const [autoSave, setAutoSave] = useState(query.autoSave == "true");
  const [directoryId, setDirectoryId] = useState(undefined);

  const autoSaveRef = useRef<any>(null);

  const getPost = async () => {
    apiAxios
      .post(`/api/post/auth/${id}`)
      .then(({ data }: any) => {
        setContents(data.data.post.contents);
        setTitle(data.data.post.title);
        setContentsType(data.data.post.contents_type);
        setIsVisible(data.data.post.is_visible);
        setDirectoryId(data.data.post.directory_id);
      })
      .catch((err) => (window.location.href = "/"));
  };

  useEffect(() => {
    autoSaveRef.current = () => {
      if (autoSave) {
        setLoading(true);
        callSubmitAPI({
          refresh: false,
        });
      }
    };
  }, [autoSave, contents, isVisible, title]);

  useEffect(() => {
    const autoSaveInterval = setInterval(() => {
      autoSaveRef.current();
    }, 15 * 1000);
    return () => clearInterval(autoSaveInterval);
  }, []);

  useEffect(() => {
    if (id) {
      getPost();
    } else {
      setContents("");
      setContentsType("markdown");
    }
  }, []);

  const submitForm = async (param: any) => {
    setLoading(true);
    callSubmitAPI({
      tags: param.tags ?? [],
      refresh: true,
    });
  };
  const callSubmitAPI = ({
    tags = [],
    refresh = true,
  }: {
    tags?: any;
    refresh?: boolean;
  }) => {
    apiAxios
      .post("/api/post/save", {
        id: +id,
        tags: tags ?? [],
        title,
        is_visible: isVisible,
        contents,
        post_type: type,
        member_id: userId,
        contents_type: contentsType,
        directory_id: directoryId,
      })
      .then((response) => {
        if (response.data.success === true) {
          setLoading(false);
          if (refresh) {
            history.push(`/post/${type}/${response.data.data.post.id}`);
          } else {
            if (!id) {
              history.push(
                `/post/update/${type}/${response.data.data.post.id}?autoSave=${autoSave}`
              );
            }
          }
          // showMessage("Saved");
        }
      })
      .catch((error: any) => {
        showMessage(error.response.data.data);
        setLoading(false);
        return error.response.data;
      });
  };

  return (
    <Content style={{ padding: "0 20px", paddingTop: "63px" }}>
      <Form
        // wrapperCol={{ span: 14 }}
        // onFinish={handleSubmit(submitForm)}
        onFinish={submitForm}
      >
        <Form.Item label="Title">
          <Input
            type="text"
            name="title"
            value={title}
            onChange={(e: any) => setTitle(e.target.value)}
            placeholder="Title"
          />
        </Form.Item>
        <Form.Item label="Visible" valuePropName="checked">
          <Switch
            checked={isVisible}
            onChange={() => setIsVisible(!isVisible)}
          />
        </Form.Item>
        <Form.Item label="Auto Save" valuePropName="checked">
          <Switch checked={autoSave} onChange={() => setAutoSave(!autoSave)} />
        </Form.Item>
        <Form.List name="tags">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <Space
                  key={key}
                  style={{ display: "flex", marginBottom: 0 }}
                  align="baseline"
                >
                  <Form.Item
                    {...restField}
                    name={[name, "tag"]}
                    fieldKey={[fieldKey, "tag"]}
                    label="Tag"
                  >
                    <Input placeholder="Tag name" />
                  </Form.Item>

                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add tags
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <DirectoryForm setDirectoryId={setDirectoryId} type={type} />
        <button
          type="button"
          onClick={() =>
            navigator.clipboard.writeText(`<pre id="index">
<h3>목차</h3>
<a href="#1">ReactiveX 의 구성요소들</a>
<a href="#2">Observable</a>
<a href="#3">Observer</a>
<a href="#4">Operator</a>
&nbsp;<a href="#4-1">선택 관련 Operator</a>
&nbsp;<a href="#4-2">Transformation Operator</a>
&nbsp;<a href="#4-3">시간 관련 Operator</a>
&nbsp;<a href="#4-4">스트림 결합 Operator</a>
&nbsp;<a href="#4-5">기타 유용한 Operator</a>
<a href="#5">Subject</a>
</pre>`)
          }
          style={{ fontWeight: "bold" }}
        >
          목차 넣기(클립보드에 복사)
        </button>
        {(id ? contents !== undefined && contentsType !== undefined : true) && (
          <ToastEditor
            theme={isDarkMode ? "dark" : "light"}
            content={contents ?? ""}
            setContent={setContents}
            contentsType={contentsType ?? "markdown"}
            setContentsType={setContentsType}
          />
        )}

        <Button
          style={{ float: "right" }}
          type="link"
          htmlType="submit"
          loading={loading}
        >
          Post
        </Button>
      </Form>
    </Content>
  );
};

export default PostForm;
