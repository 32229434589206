import axios from "axios";
import { AuthCookie } from "../constants/cookie";
import { getCookie } from "../util";

export const apiUrl = "https://heejjeeh.me";
// export const apiUrl = window.origin;

export const apiAxios = axios.create({
  baseURL: apiUrl,
  ...(getCookie(AuthCookie)
    ? {
        headers: {
          Authorization: `Bearer ${getCookie(AuthCookie)}`,
        },
      }
    : {}),
});
