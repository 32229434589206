import DirectoryTree from "antd/lib/tree/DirectoryTree";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { apiAxios } from "../../api";
import { SidebarSection } from "../../assets/styles/common";

const Directories = ({ type }: any) => {
  const history = useHistory();
  const [directories, setDirectories] = useState<any[]>([]);

  const getDirectories = async () => {
    const { data } = await apiAxios.get(`/api/post/directories?type=${type}`);
    setDirectories(data.data.directories);
  };

  const onSelect = (selectedKeys: any, event: any) => {
    const postId = event.node.postId;
    if (postId) {
      // window.location.href = `/post/${type}/${postId}`;
      history.push(`/post/${type}/${postId}`);
    }
  };

  useEffect(() => {
    // setDirectories([]);
    getDirectories();
  }, [type]);

  const makeDirectoryData = (directory: any) => {
    const style = {
      fontSize: "12.5px",
      padding: "0 0 2.5px",
    };

    return {
      title: directory.name,
      key: `directory-${directory.id}`,
      isLeaf: false,
      children: [
        ...(directory.Directories ?? []).map((d: any) => makeDirectoryData(d)),
        ...directory.Posts.map((post: any) => ({
          title: post.title,
          key: `post-${post.id}`,
          isLeaf: true,
          postId: post.id,
          style,
        })),
      ],
      style,
    };
  };

  return (
    <>
      <style>
        {`
          .ant-tree .ant-tree-node-content-wrapper {
            padding: 0px
          }
        `}
      </style>
      {directories.length > 0 && (
        <SidebarSection>
          <h4>Directories</h4>
          <DirectoryTree
            // showLine
            switcherIcon={<></>}
            // defaultExpandAll={true}
            defaultExpandedKeys={[
              // 개발
              "directory-1",
              // 독서
              "directory-2",
              // 편지
              "directory-14",
              // 여행
              "directory-32",
            ]}
            onSelect={onSelect}
            treeData={directories.map(makeDirectoryData)}
          />
        </SidebarSection>
      )}
    </>
  );
};
export default Directories;
