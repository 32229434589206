import { Col, Drawer, Menu, notification, Row } from "antd";
import { Header } from "antd/lib/layout/layout";
import { useContext, useState } from "react";
import { AppContext } from "../..";
import { BlogDivider } from "../../assets/styles/common";
import { AuthCookie } from "../../constants/cookie";
import { deleteCookie, getDeviceWidth } from "../../util";
import About from "../sidebar/About";
import LogInModal from "../user/LogInModal";
import Sponsor from "./Sponsor";

const AppLayout = ({ children }: any) => {
  const {
    isMobile,
    isLoggedIn,
    isAdmin,
    isActive,
    setIsAdmin,
    setIsLoggedIn,
    setIsActive,
    setUserId,
  } = useContext(AppContext);

  const [drawerVisible, setDrawerVisible] = useState(false);

  // useEffect(() => {
  //   setTimeout(() => {
  //     openNotification("bottomRight");
  //   }, 1000 * 30);
  // }, []);

  const toggleDrawer = () => {
    setDrawerVisible(!drawerVisible);
  };

  const logout = () => {
    deleteCookie(AuthCookie);
    setIsAdmin(false);
    setIsLoggedIn(false);
    setIsActive(false);
    setUserId(undefined);
  };

  const getLeftPadding = () => {
    const device = getDeviceWidth();
    switch (device) {
      case "xs":
        return "0%";
      case "sm":
        return "10%";
      case "md":
        return "10%";
      case "lg":
        return "18%";
      case "xl":
        return "32%";
      case "xxl":
        return "32%";
      default:
        return "0%";
    }
  };
  const openNotification = (msg: string) => {
    notification.open({
      message: "Sponsor",
      duration: isMobile ? 10 : 30,
      description: (
        <>
          {isMobile ? (
            <a href="https://qr.kakaopay.com/2810060110000528814370209c402312">
              Buy me a coffee with Kakaopay
            </a>
          ) : (
            <>
              <p>Buy me a coffee with Kakaopay</p>
              <Sponsor />
            </>
          )}
        </>
      ),
      placement: "bottomRight",
    });
  };

  return (
    <>
      <Row>
        <Col xs={0} md={1} lg={2} xl={5}></Col>
        <Col xs={24} md={22} lg={20} xl={14}>
          <Header
            className="header"
            style={{
              position: "sticky",
              // top: 0,
              // zIndex: 1,
              // width: '100%',
            }}
          >
            {isMobile ? (
              <Row>
                <Col xs={5} md={5}>
                  <div style={{ paddingLeft: "10px" }} onClick={toggleDrawer}>
                    Menu
                  </div>
                </Col>
                <Col xs={14} md={14}></Col>
                <Col xs={5} md={5}>
                  {/* <ThemeToggle /> */}
                </Col>

                <Drawer
                  placement="right"
                  closable={false}
                  onClose={toggleDrawer}
                  visible={drawerVisible}
                  forceRender={true}
                >
                  {isMobile && <div id="mobile-post-detail-index"></div>}

                  <h4>Menu</h4>

                  <BlogDivider />
                  <div>
                    <h4>
                      <a href="/post/dev">Dev</a>
                      {/* <Link to="/post/dev">Dev</Link> */}
                    </h4>
                  </div>
                  {isActive && (
                    <div>
                      <h4>
                        <a href="/post/diary">Diary</a>
                        {/* <Link to="/post/diary">Diary</Link> */}
                      </h4>
                    </div>
                  )}

                  {isLoggedIn ? (
                    <>
                      <div>
                        <h4>
                          <a href="/user/mypage">Mypage</a>
                          {/* <Link to="/user/mypage">Mypage</Link> */}
                        </h4>
                      </div>
                      <div>
                        <h4>
                          <a onClick={logout}>Log out</a>
                        </h4>
                      </div>
                    </>
                  ) : (
                    <>
                      <div>
                        <h4>
                          <LogInModal logInButton={<div>Log in</div>} />
                        </h4>
                      </div>
                    </>
                  )}
                  {isAdmin && (
                    <div>
                      {/* <Link to="/settings">Settings</Link> */}
                      <a href="/settings">Settings</a>
                    </div>
                  )}
                  <div style={{ marginTop: 100 }}>
                    <About />
                  </div>
                  {isAdmin && (
                    <div>
                      <a href="?refresh=true">Clear Cache</a>
                    </div>
                  )}
                </Drawer>
              </Row>
            ) : (
              <Menu
                theme="light"
                mode="horizontal"
                defaultSelectedKeys={["issue"]}
              >
                <Menu.Item key="issue">
                  <a href="/post/dev">Dev</a>
                  {/* <Link to="/post/dev">Dev</Link> */}
                </Menu.Item>
                {isAdmin && (
                  <Menu.Item key="diary">
                    {isActive && <a href="/post/diary">Diary</a>}
                  </Menu.Item>
                )}
                {isLoggedIn ? (
                  <>
                    <Menu.Item key="my-page">
                      <a href="/user/mypage">Mypage</a>
                      {/* <Link to="/user/mypage">Mypage</Link> */}
                    </Menu.Item>
                    <Menu.Item key="log-out">
                      <a onClick={logout}>Log out</a>
                    </Menu.Item>
                  </>
                ) : (
                  <>
                    <Menu.Item key="log-in">
                      <LogInModal logInButton={<div>Log in</div>} />
                    </Menu.Item>
                  </>
                )}
                {isAdmin && (
                  <Menu.Item key="settings">
                    <a href="/settings">Settings</a>
                    {/* <Link to="/settings">Settings</Link> */}
                  </Menu.Item>
                )}
                {isAdmin && (
                  <Menu.Item key="clear-cache">
                    <a href="?refresh=true">Clear Cache</a>
                  </Menu.Item>
                )}

                {/* <Menu.Item key="themeToggle">
                  <ThemeToggle />
                </Menu.Item> */}
              </Menu>
            )}
          </Header>
          {children}
        </Col>
        <Col xs={0} md={1} lg={2} xl={5}></Col>
      </Row>
    </>
  );
};
export default AppLayout;
