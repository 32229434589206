import { BackTop, Breadcrumb, Divider, Skeleton } from "antd";
import { useContext, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { AppContext } from "../..";
import { apiAxios } from "../../api";
import PostDetailStyle from "../../assets/styles/post-detail-style";
import { Member } from "../../dto/Member";
import { Post } from "../../dto/Post";
import { showMessage } from "../../util";
import CommentForm from "../comment/CommentForm";
import CommentList from "../comment/CommentList";
import MainContent from "../common/MainContent";
import MarkdownViewer from "../common/MarkdownViewer";
import WysiwygViewer from "../common/WysiwygViewer";
import useConfirm from "../hooks/UseConfirm";

const PostDetail = ({ match }: any) => {
  const { type, postId } = useMemo(() => match.params, [match.params]);
  const { isDarkMode, isAdmin, userId, isLoggedIn, isMobile } =
    useContext(AppContext);
  const [post, setPost] = useState<Post>();
  const [tags, setTags] = useState<string[]>([]);
  const [member, setMember] = useState<Member>();
  const [comments, setComments] = useState<any[]>([]);
  const [directoryNames, setDirectoryNames] = useState<string[]>([]);

  const getPost = async () => {
    setPost(undefined);
    const { data } = await apiAxios
      .post(`/api/post/${postId}`)
      .catch((error: any) => {
        showMessage(error.response.data.data);
        window.location.href = "/";

        return error.response.data;
      });
    setPost(data.data.post);
    setTags(data.data.tags);
    setMember(data.data.member);
    setComments(data.data.comments);
    setDirectoryNames(data.data.directoryNames);
  };
  const deletePost = useConfirm("Delete?", async () => {
    apiAxios
      .post(`/api/post/delete/${postId}`)
      .then(({ data }: any) => {
        if (data.success) {
          window.location.href = `/post/${type}`;
        }
      })
      .catch((error: any) => {
        showMessage(error.response.data.data);
        window.location.href = "/";

        return error.response.data;
      });
  });
  const deleteTag = useConfirm("Delete?", async (tag: string) => {
    apiAxios
      .post(`/api/tag/delete`, {
        tag,
        postId: +postId,
      })
      .then(({ data }: any) => {
        if (data.success) {
          getPost();
        }
      });
  });

  const getMobileMenubar = () =>
    document.getElementById("mobile-post-detail-index");

  const setMobileMenubar = () => {
    const mobileMenuBar = getMobileMenubar();
    if (mobileMenuBar) {
      const index = document.getElementById("index");

      if (index) {
        index.style.fontSize = "13px";
        index.style.lineHeight = "1.5715";

        const fragment = document.createDocumentFragment();
        fragment.appendChild(index);
        mobileMenuBar.appendChild(fragment);
      }
    }
  };
  const resetMobileMenubar = () => {
    const mobileMenuBar = getMobileMenubar();
    if (mobileMenuBar) {
      mobileMenuBar.innerHTML = "";
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getPost().then(isMobile ? setMobileMenubar : undefined);
    return isMobile ? resetMobileMenubar : undefined;
  }, [postId, isDarkMode]);
  return (
    <MainContent type={type}>
      <Helmet>
        <title>{post?.title}</title>
        <meta property="og:title" content={post?.title} />
      </Helmet>
      <PostDetailStyle />
      <BackTop />
      <h2 style={{margin: '5px 0px'}}>{post?.title}</h2>
      {/*<Avatar size="small" icon={<UserOutlined />} />*/}
      {/* <span style={{ marginLeft: "10px" }}>{member?.username}</span> */}
      {
        <Breadcrumb separator=">">
          {directoryNames.map((name, idx) => (
            <Breadcrumb.Item key={`directory-${idx}`}>
              {name}
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      }
      {/* <span>
        {tags.map((t, idx) =>
          isAdmin || post?.member_id === userId ? (
            <Tooltip
              key={`tag-${idx}`}
              title={
                <Button type="link" size="small" onClick={() => deleteTag(t)}>
                  삭제
                </Button>
              }
            >
              <Link to={`/post/${type}?tag=${t}`}>#{t} </Link>
            </Tooltip>
          ) : (
            <Link key={`tag-${idx}`} to={`/post/${type}?tag=${t}`}>
              #{t}{" "}
            </Link>
          )
        )}
      </span> */}
      <div>
        <span>{member?.username}</span>

        <span style={{ float: "right" }}>
          {post?.regdate.slice(0, 10).replace(/-/g, "")}
        </span>
      </div>
      {(isAdmin || post?.member_id === userId) && (
        <>
        <div>
          <Link to={`/post/update/${type}/${postId}`}>update</Link>
          &nbsp;
          <a onClick={deletePost}>
            delete
          </a>
        </div>
        {/* <br /> */}
        </>
      )}

     <Divider />
      {post ? (
        <>
          {post.contents_type.startsWith("wysiwyg") ? (
            <WysiwygViewer
              theme={isDarkMode ? "dark" : "light"}
              contents={post.contents}
            />
          ) : (
            <MarkdownViewer
              theme={isDarkMode ? "dark" : "light"}
              markdown={post.contents}
            />
          )}

          <h5 style={{ float: "right" }}>조회수 {post?.view_count}</h5>

          <h2 style={{ marginTop: "30px" }}>Comments</h2>
          <CommentList comments={comments} />
          {isLoggedIn && <CommentForm postId={postId} />}
        </>
      ) : (
        <>
          <Skeleton active paragraph={{ rows: 5 }} />
          <Skeleton active paragraph={{ rows: 3 }} />
          <Skeleton active paragraph={{ rows: 10 }} />
        </>
      )}
    </MainContent>
  );
};

export default PostDetail;
